import React from 'react';
import { graphql } from 'gatsby';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import { SanityImage } from '@components/sanity/sanityImage';
import SimplePortableText from '@components/sanity/simplePortableText';
import CardCarousel from '@components/pageModules/modules/cardCarousel';
import { useArticleQuery } from '../GraphQl/useArticleQuery';

const ArticleTemplate = ({ data: { page } }) => {
  const { seo, title, articleIntro, articleImage, articleContent, date, slug } =
    page || {};
  const { nodes } = useArticleQuery();

  //filter out the current article
  const filteredArticles = nodes?.filter(
    (node) => node?.slug?.current !== slug?.current,
  );

  return (
    <Layout reverseHeader={true}>
      <PageMeta {...seo} />
      <div className=' pt-36 xl:pt-96  bg-light-blue text-grey px-gutter'>
        <div className='text-center pb-space-m lg:px-gutter'>
          <p className='blockH7 text-blue'>{date}</p>
          <h1 className='richH0 mb-7'>{title}</h1>
          <p className='richH2'>{articleIntro}</p>
        </div>
        <div className='aspect-w-5 aspect-h-3 relative  '>
          <SanityImage
            image={articleImage}
            className='absolute top-0 left-0 w-full h-full object-cover'
          />
        </div>
        <div className='pt-space-m lg:px-20 '>
          <SimplePortableText text={articleContent} />
        </div>
      </div>
      <div className='bg-light-blue pt-space-m pb-space-m'>
        <CardCarousel
          items={filteredArticles}
          heading='Other News'
          path={'news'}
        />
      </div>
    </Layout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query standardArticleuery($slug: String!) {
    page: sanityArticle(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      slug {
        current
      }
      date(formatString: "DD MMMM, YYYY")
      articleIntro
      articleImage {
        ...ImageWithPreview
      }
      articleContent: _rawArticleContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
